// extracted by mini-css-extract-plugin
export var about_author = "singlefeatureprod-module--about_author--C1+VU";
export var article_container = "singlefeatureprod-module--article_container--jfSHs";
export var article_container_large = "singlefeatureprod-module--article_container_large--88gVY";
export var article_text = "singlefeatureprod-module--article_text--utQNC";
export var contact_us = "singlefeatureprod-module--contact_us--BiBzm";
export var cover_credits = "singlefeatureprod-module--cover_credits--LmeG4";
export var featured_photo = "singlefeatureprod-module--featured_photo--I5g8K";
export var hideOnBigScreen = "singlefeatureprod-module--hideOnBigScreen--mI5eu";
export var main_content_wrapper = "singlefeatureprod-module--main_content_wrapper--ZB+b2";
export var qna = "singlefeatureprod-module--qna--r0cYx";
export var quote = "singlefeatureprod-module--quote--q+m-I";
export var quote_icon = "singlefeatureprod-module--quote_icon--2nT45";
export var share = "singlefeatureprod-module--share--Vz0P1";
export var share_vertical = "singlefeatureprod-module--share_vertical--goOaR";
export var social = "singlefeatureprod-module--social--yvBEJ";
export var socials = "singlefeatureprod-module--socials--AaDb7";