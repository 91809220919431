import React from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import {
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from 'react-share';
import { graphql } from 'gatsby';
import {
  FaEnvelope,
  FaFacebookF,
  FaLinkedinIn,
  FaQuoteLeft,
  FaTwitter,
  FaWhatsapp,
} from 'react-icons/fa';
import Layout from '../layouts';
import SingleFeatured from '../components/Banners/SingleFeatured';
import SEO from '../components/SearchEngine';
import * as styles from './singlefeatureprod.module.scss';

const SingleFeatureProd = ({ location, data }) => {
  const { siteUrl } = data.site.siteMetadata;
  const canonicalUrl = siteUrl + location.pathname;
  const hashtags = ['TAIKinspires'];

  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;

  const bannerData = {
    title: frontmatter.title,
    author: frontmatter.author,
    date: frontmatter.date,
    excerpt: frontmatter.excerpt,
  };

  const customTwitterTitle = `${frontmatter.title} #TAIKinspires via @theafricaiknow_`;

  return (
    <Layout
      bannerHeight='700px'
      bannerStyle={{ backgroundImage: 'none' }}
      bannerChildren={<SingleFeatured featureData={bannerData} />}
      heroBackgroundColor='altlightest'
    >
      <SEO
        title={frontmatter.title}
        image={`${siteUrl}${frontmatter.image_1.childImageSharp.fluid.src}`}
        description={frontmatter.excerpt}
        type='article'
        url={canonicalUrl}
      />
      <section className='bg-altlightest w-100'>
        <div className={`row ${styles.featured_photo}`}>
          <img
            className='img-fluid p-0'
            src={frontmatter.image_1.childImageSharp.fluid.src}
            alt={frontmatter.subject}
          />
        </div>
      </section>
      <section className={`${styles.main_content_wrapper} bg-altsuperlight w-100`}>
        <div className={`${styles.article_container}`}>
          <div className={`${styles.share_vertical} d-none d-lg-block`}>
            <h2>Share</h2>
            {/* <TwitterShareButton
              className={`me-3 ${styles.social}`}
              title={frontmatter.title}
              hashtags={hashtags}
              url={canonicalUrl}
              via='theafricaiknow_'
            >
              <FaTwitter size={27} />
            </TwitterShareButton> */}
            <TwitterShareButton
              className={`me-3 ${styles.social}`}
              title={customTwitterTitle}
              url={canonicalUrl}
            >
              <FaTwitter size={27} />
            </TwitterShareButton>

            <FacebookShareButton
              className={`${styles.social}`}
              hashtags={hashtags}
              url={canonicalUrl}
              quote={frontmatter.title}
            >
              <FaFacebookF size={27} />
            </FacebookShareButton>

            <LinkedinShareButton
              className={`${styles.social}`}
              title={frontmatter.title}
              url={canonicalUrl}
            >
              <FaLinkedinIn size={27} />
            </LinkedinShareButton>

            <WhatsappShareButton
              className={`${styles.social} ${styles.hideOnBigScreen}`}
              title={frontmatter.title}
              url={canonicalUrl}
            >
              <FaWhatsapp size={27} />
            </WhatsappShareButton>

            <EmailShareButton
              className={styles.social}
              subject={frontmatter.title}
              body={canonicalUrl}
              url={canonicalUrl}
            >
              <FaEnvelope size={27} />
            </EmailShareButton>
          </div>
          <div className={`row ${styles.article_text}`}>
            <ReactMarkdown remarkPlugins={[gfm]} rehypePlugins={[rehypeRaw]}>
              {frontmatter.content}
            </ReactMarkdown>
          </div>
        </div>

        <div className={`row ${styles.featured_photo}`}>
          <img
            className='img-fluid p-0'
            src={frontmatter.image_2.childImageSharp.fluid.src}
            alt={frontmatter.subject}
          />
          {frontmatter.photo_credit_2 !== '' && (
            <span className={`${styles.cover_credits} mt-2 mb-2 px-0`}>
              {/* Photo credit: <div dangerouslySetInnerHTML={{ __html: photo_credit }} /> */}
              Photo credit:{' '}
              <ReactMarkdown
                remarkPlugins={[gfm]}
                rehypePlugins={[rehypeRaw]}
                components={{ p: 'span' }}
              >
                {frontmatter.photo_credit_2}
              </ReactMarkdown>
            </span>
          )}
        </div>

        <div className={`${styles.article_container_large}`}>
          <p className={`${styles.quote}`}>
            <FaQuoteLeft className={`${styles.quote_icon}`} size={42} />
            {frontmatter.quote_1}
          </p>

          <div className={`${styles.qna}`} dangerouslySetInnerHTML={{ __html: html }} />
        </div>

        <div className={`${styles.article_container} p-0`}>
          {frontmatter.about_author !== '' && (
            <div className={`${styles.about_author}`}>
              <h2>About the author</h2>

              <ReactMarkdown remarkPlugins={[gfm]} rehypePlugins={[rehypeRaw]}>
                {frontmatter.about_author}
              </ReactMarkdown>
            </div>
          )}

          <p className={`${styles.contact_us}`}>
            Know someone we should feature? Or are you of African descent and would you like to
            share your journey with us? Email us at{' '}
            <a href='mailto:editors@theafricaiknow.org'>editors@theafricaiknow.org</a>
          </p>

          <div className={`${styles.share}`}>
            <h2>Share this article</h2>
            <div className='d-flex justify-content-center flex-md-nowrap flex-wrap'>
              <TwitterShareButton
                className={`me-3 ${styles.social}`}
                title={frontmatter.title}
                hashtags={hashtags}
                url={canonicalUrl}
                via='theafricaiknow_'
              >
                <FaTwitter size={27} />
              </TwitterShareButton>

              <FacebookShareButton
                className={`me-3 ${styles.social}`}
                hashtags={hashtags}
                url={canonicalUrl}
                quote={frontmatter.title}
              >
                <FaFacebookF size={27} />
              </FacebookShareButton>

              <LinkedinShareButton
                className={`me-3 ${styles.social}`}
                title={frontmatter.title}
                url={canonicalUrl}
              >
                <FaLinkedinIn size={27} />
              </LinkedinShareButton>

              <WhatsappShareButton
                className={`me-3 ${styles.social} ${styles.hideOnBigScreen}`}
                title={frontmatter.title}
                url={canonicalUrl}
              >
                <FaWhatsapp size={27} />
              </WhatsappShareButton>

              <EmailShareButton
                className={`me-3 ${styles.social}`}
                subject={frontmatter.title}
                body={canonicalUrl}
                url={canonicalUrl}
              >
                <FaEnvelope size={27} />
              </EmailShareButton>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        id
        slug
        title
        excerpt
        date
        author
        about_author
        category
        subject
        content
        quote_1
        quote_2
        photo_credit_2
        image_1 {
          childImageSharp {
            fluid {
              src
            }
          }
        }
        image_2 {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export default SingleFeatureProd;
