import React from 'react';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import * as styles from './SingleFeatured.module.scss';

const SingleFeatured = ({ featureData }) => {
  const { title, author, excerpt, date } = featureData;

  return (
    <>
      <div className={`${styles.banner_container}`}>
        <div className='column align-items-center'>
          <h1 className={`${styles.title} text-altdarker`}>{title}</h1>

          <div className={`bg-altdarker rounded-pill ${styles.banner_divider}`} />

          <p className={`${styles.intro} text-altdarker`}>
            <ReactMarkdown remarkPlugins={[gfm]} rehypePlugins={[rehypeRaw]}>
              {excerpt}
            </ReactMarkdown>
          </p>

          <p className={`${styles.author} text-altdarker`}>
            By {author} &bull; {moment(date).format('MMMM DD, YYYY')}
          </p>
        </div>
      </div>
    </>
  );
};

export default SingleFeatured;
